import React, {useEffect, useState} from 'react'
import style from './users.module.css'
import {useDispatch, useSelector} from 'react-redux'
import {
  deleteUserInfo,
  getAllDocuments,
  getAllUsers,
} from '../../../reduxDatamanage/actions/Actions'
import {useParams} from 'react-router-dom'
import {AppDispatch, RootState} from '../../../reduxDatamanage/store'
import AddNewUser from './AddNewUser'
import {ToastContainer, toast} from 'react-toastify'
import EditUser from './EditUser'

const Users = () => {
  const [showAddNewUserPopup, setShowAddNewUserPopup] = useState(false)
  const {users} = useSelector((state: RootState) => state)
  const [loader, setLoader] = useState<any>(true)
  const {id} = useParams()
  const companyId = Number(id)
  const dispatch: AppDispatch = useDispatch()
  const [allUsers, setAllUsers] = useState<any[]>()
  const documents = useSelector((state: RootState) => state.documents)
  const [docs, setDocs] = useState<any[]>([])
  const error = useSelector((state: RootState) => state.error)
  const token = localStorage.getItem('token') as string
  const [userId, setUserId] = useState()

  useEffect(() => {
    if (documents) {
      setDocs(documents)
    }
  }, [documents])

  useEffect(() => {
    // if (docs.length === 0) {
    dispatch(getAllDocuments(companyId, token))
    // }
  }, [])
  useEffect(() => {
    if (token !== '' || null) {
      // @ts-ignore
      dispatch(getAllUsers(companyId, token))
      setLoader(false)
    } else {
      setLoader(false)
    }
  }, [])

  useEffect(() => {
    if (users) {
      setAllUsers(users)
    }
  }, [users])

  console.log('DOCS', docs)
  console.log('USERS ->', allUsers)

  const deleteData = (userId: any) => {
    if (window.confirm('Are you sure, you want delete user')) {
      dispatch(deleteUserInfo(id, userId, token))
    }
  }
  const selectedUserId = (user: any) => {
    setUserId(user)
  }
  return (
    <>
      <div className='card'>
        <div className='card-header border-0 p-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Users List</span>
          </h3>
          <div className={style.set_add_btn}>
            <AddNewUser
              show={showAddNewUserPopup}
              setShow={setShowAddNewUserPopup}
              document={docs}
            />
          </div>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            {loader ? (
              <div style={{height: '200px'}}>
                <p className={style.loader}></p>
              </div>
            ) : docs.length === 0 ? (
              <h4 className='text-gray-700'>
                Please upload the document before adding any users to the company.
              </h4>
            ) : allUsers?.length === 0 ? (
              <h4 className='text-gray-700'>
                Currently no users are available. Please add a new user.
              </h4>
            ) : (
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-150px'>User Name</th>
                    <th className='min-w-140px'>User Contact</th>
                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {allUsers?.map((user: any) => {
                    return (
                      <tr key={user.id}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <p className='text-gray-700 fw-bold text-hover-primary d-block fs-6'>
                              {user.name}
                            </p>
                          </div>
                        </td>
                        <td>
                          <p className='text-gray-700 fw-bold text-hover-primary d-block fs-6'>
                            {user.mobile}
                          </p>
                        </td>
                        <td>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <div onClick={() => selectedUserId(user)}>
                              <EditUser user={user} />
                            </div>
                            <button
                              className='btn btn-icon btn-bg-secondary btn-active-color-danger btn-sm me-5  p-3'
                              style={{width: 'auto'}}
                              onClick={() => deleteData(user.id)}
                            >
                              <i className='bi bi-trash3-fill fs-2'></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Users
