import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {useDispatch, useSelector} from 'react-redux'
// import {editUserDetails} from '../../../reduxDatamanage/actions/Actions'
import {AppDispatch, RootState} from '../../../reduxDatamanage/store'
import {UPDATE_USER} from '../../../modules/auth/ConstentVariable'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {getAllUsers} from '../../../reduxDatamanage/actions/Actions'

const EditUser = ({user}: any) => {
  const token = localStorage.getItem('token') as string
  const {id}: any = useParams()
  const [show, setShow] = useState(false)
  const [editUser, setEditUser] = useState({})
  //   const user = useSelector((state: RootState) => state.user)
  const dispatch: AppDispatch = useDispatch()
  const handleShow = () => setShow(true)

  const validationSchema = Yup.object({
    mobile: Yup.number().required(),
    name: Yup.string().required(),
  })
  //   console.log('USER', user)
  const editUserDetails = async () => {
    console.log('TOKEN', token)

    await axios
      .post(`${UPDATE_USER}/${user?.id}`, {
        headers: {'auth-token': token},
      })
      .then((res: any) => {
        console.log('ACTION', res)
        setEditUser(res.data.data)
      })
      .catch((err: any) => {
        console.log('error', err)
      })
  }

  const formik = useFormik({
    initialValues: {
      name: user?.name,
      mobile: user?.mobile,
    },
    validationSchema,
    onSubmit: (values) => {
      const {mobile, name} = values
      const data = {
        mobile: mobile,
        name: name,
      }
      axios
        .post(`${UPDATE_USER}/${user?.id}`, data, {
          headers: {'auth-token': token},
        })
        .then((res: any) => {
          console.log('RES SAVE ==>', res.data.data)
          dispatch(getAllUsers(id, token))
        })
      // @ts-ignore
      //   setNewMobile(data.mobile)
      setShow(false)
    },
  })
  return (
    <>
      <div
        className='btn btn-icon btn-bg-secondary btn-active-color-success btn-sm me-5  p-3'
        onClick={handleShow}
      >
        <i className='bi bi-pencil-square fs-2'></i>
      </div>
      <Modal show={show} className={`modal-md `}>
        <Modal.Header>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div>
              <label className='form-label'>User Name</label>
              <div className='mb-3 row'>
                <div className='col-8 size-sm'>
                  <input
                    type='text'
                    name='name'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='form-control col-12'
                    placeholder='Enter user name'
                  />
                </div>
                {formik.touched.name && formik.errors.name && (
                  // @ts-ignore
                  <div className='text-danger'>* {formik.errors.name}</div>
                )}
              </div>
            </div>
            <div className='mb-5'>
              <label className='form-label'>User Mobile Number</label>

              <div className='marginBottom'>
                <PhoneInput
                  country={'canada'}
                  value={formik.values.mobile}
                  onChange={(mobile) => formik.setFieldValue('mobile', mobile)} // Set the mobile value
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.touched.mobile && formik.errors.mobile && (
                // @ts-ignore
                <div className='text-danger'>* {formik.errors.mobile}</div>
              )}
            </div>
            <Modal.Footer>
              <div className='mt-5 d-flex justify-content-end'>
                <Button
                  variant='secondary'
                  size='sm'
                  className='me-3'
                  onClick={() => setShow(false)}
                >
                  Close
                </Button>
                <Button type='submit' variant='primary' size='sm'>
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditUser
