import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {getAllUsers} from '../../../reduxDatamanage/actions/Actions'
import axios from 'axios'
import {ADD_USER_COMAPNY} from '../../../modules/auth/ConstentVariable'
import {ToastContainer, toast} from 'react-toastify'
import {AppDispatch} from '../../../reduxDatamanage/store'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Button, Modal} from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import NewUser from './NewUser'

const AddNewUser = ({show, setShow, document}: {show: any; setShow: any; document: any}) => {
  const [showNewUserPopup, setShowNewUserPopup] = useState(false)
  const {id} = useParams()
  const companyId = Number(id)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = localStorage.getItem('token') as string
  const [newMobile, setNewMobile] = useState()

  const validationSchema = Yup.object({
    mobile: Yup.number().required(),
  })

  const formik = useFormik({
    initialValues: {
      mobile: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const {mobile} = values
      const data = {
        mobile: mobile,
      }
      // @ts-ignore
      setNewMobile(data.mobile)
      axios
        .post(`${ADD_USER_COMAPNY}/${id}`, data, {
          headers: {'auth-token': token},
        })
        .then((res) => {
          // @ts-ignore

          dispatch(getAllUsers(companyId, token))
          setShow(false)
          toast.success('User added successfully')
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message)
            setShowNewUserPopup(true)
          } else {
            console.log('Error--->:', err.message)
          }
        })
    },
  })

  useEffect(() => {
    if (show) {
      setShow(false)
      setShowNewUserPopup(true)
    } else {
    }
  }, [showNewUserPopup, setShow])

  const handleShow = () => {
    formik.resetForm()
    if (document.length === 0) {
      toast.error('Please upload the document before adding any users to the company.')
      setShow(false)
    } else {
      setShow(true)
    }
  }

  return (
    <>
      <div className='d-flex align-items-center ms-5 btn btn-primary' onClick={handleShow}>
        <i className='bi bi-person-plus-fill fs-2 text-light'></i>
      </div>
      <Modal show={show} className={`modal-md `}>
        <Modal.Header>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className='mb-5'>
              <label className='form-label'>User Mobile Number</label>

              <div className='marginBottom'>
                <PhoneInput
                  country={'canada'}
                  value={formik.values.mobile}
                  onChange={(mobile) => formik.setFieldValue('mobile', mobile)} // Set the mobile value
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.touched.mobile && formik.errors.mobile && (
                <div className='text-danger'>* {formik.errors.mobile}</div>
              )}
            </div>
            <Modal.Footer>
              <div className='mt-5 d-flex justify-content-end'>
                <Button
                  variant='secondary'
                  size='sm'
                  className='me-3'
                  onClick={() => setShow(false)}
                >
                  Close
                </Button>
                <Button type='submit' variant='primary' size='sm'>
                  Add
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      {showNewUserPopup && (
        <NewUser newShow={showNewUserPopup} setNewShow={setShowNewUserPopup} mobile={newMobile} />
      )}
    </>
  )
}

export default AddNewUser
