import React, {ChangeEvent, useEffect, useState} from 'react'
import {NavLink, useNavigate, useParams} from 'react-router-dom'
import {editCompanyInfo, updateCompanyInfo} from '../../reduxDatamanage/actions/Actions'
import {useDispatch, useSelector} from 'react-redux'
import {KTIcon} from '../../../_metronic/helpers'
import {AppDispatch, RootState} from '../../reduxDatamanage/store'
import {CompanyType} from '../TypeInterface'

const EditCompany = () => {
  const token = localStorage.getItem('token') as string
  const [companyData, setCompanyData] = useState<CompanyType>({
    id: '',
    name: '',
    company_code: '',
  })
  const company = useSelector((state: RootState) => state.company)
  const dispatch: AppDispatch = useDispatch()
  const {id} = useParams()
  const navigate = useNavigate()

  console.log('obj', companyData)

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setCompanyData({...companyData, [e.target.name]: e.target.value})
  }

  useEffect(() => {
    dispatch(editCompanyInfo(id, token))
  }, [])

  useEffect(() => {
    if (company) {
      setCompanyData(company as CompanyType)
    }
  }, [company])

  const submitEditData = () => {
    dispatch(updateCompanyInfo(id, companyData, token))
    navigate('/companies')
  }

  return (
    <>
      <div className='modal-header'>
        <h1 className='modal-title fs-3 mb-5' id='staticBackdropLabel'>
          Edit Company Details
        </h1>
      </div>

      <div className='modal-body'>
        <form>
          <div className='mb-3'>
            <label className='form-label'>Company Name</label>
            <input
              type='text'
              name='name'
              value={companyData?.name}
              className='form-control'
              onChange={handleInput}
              placeholder='Enter company name'
            />
          </div>
          <div className='mb-3'>
            <label className='form-label'>Company Code</label>
            <input
              type='text'
              name='company_code'
              value={companyData?.company_code}
              className='form-control'
              onChange={handleInput}
              placeholder='Enter company code'
            />
          </div>
        </form>
      </div>
      <div className='modal-footer'>
        {/* <button type='button' className='btn btn-secondary btn-sm' data-bs-dismiss='modal'>
          Close
        </button> */}
        <button
          type='button'
          className='btn btn-primary btn-sm'
          data-bs-dismiss='modal'
          onClick={submitEditData}
        >
          Save
        </button>
      </div>
    </>
  )
}

export default EditCompany
