export const GET_COMPANYS = "GET_COMPANYS"
export const GET_USERS = "GET_USERS"
export const GET_DOCUMENTS = "GET_DOCUMENTS"
export const  ADD_NEW_COMPANY = "ADD_NEW_COMPANY"
export const  ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_DOCUMENT = "ADD_DOCUMENT"
export const EDIT_COMPANY = "EDIT_COMPANY"
export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const VERIFY_ERROR = 'DELETE_USER'
export const VIEW_COMPANY = 'VIEW_COMPANY'
// export const ADD_TO_USER = "ADD_TO_USER"
export const USERS_VIEW_DOCS = "USERS_VIEW_DOCS"
export const EDIT_USER = "EDIT_USER"

