import React, {useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {NavLink, useParams} from 'react-router-dom'
import {addNewCompany} from '../../reduxDatamanage/actions/Actions'
import {useDispatch} from 'react-redux'
import {AppDispatch} from '../../reduxDatamanage/store'

const AddNewCompany = () => {
  const token = localStorage.getItem('token') as string
  const [newCompany, setNewCompany] = useState({
    name: '',
    company_code: '',
  })
  const {id} = useParams()
  const dispatch: AppDispatch = useDispatch()

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewCompany({...newCompany, [e.target.name]: e.target.value})
  }

  const handleModel = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!newCompany.name || !newCompany.company_code) {
      alert('please fill form data')
    } else if (!newCompany.name) {
      alert('please enter company name')
    } else if (!newCompany.company_code) {
      alert('please enter company code')
    } else {
      dispatch(addNewCompany(newCompany, token))
    }
  }

  return (
    <>
      <button
        type='button'
        data-bs-toggle='modal'
        data-bs-target='#staticBackdrop'
        style={{border: 'none', backgroundColor: '#ffffff'}}
      >
        <NavLink to='#' className='btn btn-sm btn-light-primary'>
          <KTIcon iconName='plus' className='fs-3' />
          Add Company
        </NavLink>
      </button>

      {/* <!-- Modal --> */}
      <div
        className='modal fade'
        id='staticBackdrop'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                Add Company Details
              </h1>
            </div>

            <div className='modal-body'>
              <form onSubmit={(e) => handleModel(e)}>
                <div className='mb-3'>
                  <label className='form-label'>Company Name</label>
                  <input
                    type='text'
                    name='name'
                    value={newCompany.name}
                    className='form-control'
                    onChange={handleInput}
                    placeholder='Enter company name'
                  />
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Company Code</label>
                  <input
                    type='text'
                    name='company_code'
                    value={newCompany.company_code}
                    className='form-control'
                    onChange={handleInput}
                    placeholder='Enter company code'
                  />
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-secondary btn-sm'
                    data-bs-dismiss='modal'
                  >
                    Close
                  </button>
                  <button type='submit' className='btn btn-primary btn-sm' data-bs-dismiss='modal'>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddNewCompany
