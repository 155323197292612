import {FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import Companys from '../pages/companys/Companys'
import EditCompany from '../pages/companys/EditCompany'
import CompanyPage from '../pages/companyProfile/company_info/CompanyPage'
import AddNewUser from '../pages/companyProfile/users/AddNewUser'
// import VerifyUserNum from '../pages/companyProfile/users/VerifyUserNum'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/companies' />} />
        <Route path='companies' element={<Companys />} />
        {/* <Route
          path='/company'
          element={
            <SuspensedView>
              <Companys />
            </SuspensedView>
          }
        /> */}
        <Route path='/companies/:id/*' element={<CompanyPage />} />
        <Route path='/companies/:id/edit' element={<EditCompany />} />
        {/* <Route path='/companies/:id/users/adduser' element={<AddNewUser />} /> */}
        {/* <Route path='/companies/:id/users/varify' element={<VerifyUserNum />} /> */}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
