import {
  ADD_DOCUMENT,
  ADD_NEW_COMPANY,
  // ADD_NEW_USER,
  EDIT_COMPANY,
  EDIT_USER,
  GET_COMPANYS,
  GET_DOCUMENTS,
  GET_USERS,
  UPDATE_COMPANY,
  USERS_VIEW_DOCS,
  VERIFY_ERROR,
  VIEW_COMPANY,
} from '../variable/variable'

const initialState = {
  companys: [],
  users: [],
  documents: [],
  company: {},
  addDocsUser: [],
  companyUsers: [],
  usersViewDocs: [],
  user: {},
  error: null,
  // loader: false,
}
// @ts-ignore
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANYS:
      return {
        companys: action.payload,
      }
    case VIEW_COMPANY:
      return {
        company: {...action.payload},
      }

    case GET_USERS:
      return {
        users: action.payload,
      }

    case GET_DOCUMENTS:
      return {
        documents: action.payload,
      }

    case ADD_NEW_COMPANY:
      return {
        companys: [...state.companys, action.payload],
      }

    // case ADD_NEW_USER:
    //   return {
    //     users: [...state.users, action.payload],
    //   }

    case VERIFY_ERROR:
      console.log('reducer error -->', action.payload)

      return {
        ...state,
        error: action.payload,
      }

    case ADD_DOCUMENT:
      return {
        documents: [...state.documents, {...action.payload}],
      }

    case EDIT_COMPANY:
      return {
        ...state,
        company: action.payload,
      }
    case UPDATE_COMPANY:
      const index = state.companys.findIndex((company: any) => company.id === action.payload.id)
      const updateCompany: any = [...state.companys]
      updateCompany.splice(index, 1, action.payload)
      return {
        ...state,
        companys: [...updateCompany],
      }
    case EDIT_USER:
      return {
        ...state,
        user: action.payload,
      }
    case USERS_VIEW_DOCS:
      console.log('REDUCER DATA', action.payload)

      return {
        usersViewDocs: action.payload,
      }

    default:
      return state
  }
}
