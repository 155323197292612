import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'
import {AppDispatch, RootState} from '../../../reduxDatamanage/store'
import {addNewDocument} from '../../../reduxDatamanage/actions/Actions'

const AddDocumentsModal = () => {
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const [zip_path, setZip_path] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [entryPoint, setEntryPoint] = useState('')
  const dispatch: AppDispatch = useDispatch()
  const {id} = useParams()
  const error = useSelector((state: RootState) => state.error)
  const token = localStorage.getItem('token') as string
  const uploadNewDocument = async (e: any) => {
    e.preventDefault()
    setUploading(true)
    const formData = new FormData()
    // @ts-ignore
    formData.append('company_id', id)
    // @ts-ignore
    formData.append('zip_path', zip_path)
    formData.append('entry_point', entryPoint)

    try {
      await dispatch(addNewDocument(formData, id, token))
      toast.done('Document uploaded')
      setZip_path(null)
      if (!error) {
        // setUploading(false)
        toast.success('File is successfully uploaded')
      } else {
        setShow(true)
        // toast.error(error)
      }
    } catch (err) {
      toast.error('Error uploading file')
    } finally {
      setUploading(false)
      setShow(false)
    }
  }

  return (
    <>
      <div className='d-flex align-items-center justify-content-center'>
        <Button variant='primary' onClick={handleShow}>
          <i className='bi bi-folder-plus fs-1'></i>
        </Button>
      </div>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Add Documents Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={uploadNewDocument}>
            <div className='mb-3'>
              <label className='form-label'>Documents</label>
              <input
                type='file'
                accept='.zip,.rar,.7zip'
                onChange={(e: any) => setZip_path(e.target.files[0])}
                className='form-control'
                placeholder='chose file'
                required
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'>Entry Point</label>
              <input
                type='text'
                accept='.zip,.rar,.7zip'
                onChange={(e: any) => setEntryPoint(e.target.value)}
                className='form-control form-control-solid'
                placeholder='Enter entry point'
                required
              />
            </div>

            <div className='mt-5 d-flex justify-content-end'>
              <Button variant='secondary' size='sm' className='me-3' onClick={() => setShow(false)}>
                Close
              </Button>
              <Button type='submit' variant='primary' size='sm' disabled={uploading}>
                {uploading ? 'Uploading...' : 'Submit'}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddDocumentsModal
