import React from 'react'
import {Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import Users from '../users/Users'
import Documents from '../documents/Documents'
import CompanyInfo from './CompanyInfo'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'companies',
    path: '/companies/id/users',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CompanyPage = () => {
  const {id} = useParams()
  return (
    <Routes>
      <Route
        element={
          <>
            <CompanyInfo />
            <Outlet />
          </>
        }
      >
        <Route
          path='users'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Users</PageTitle>
              <Users />
            </>
          }
        />
        <Route
          path='document'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Documents</PageTitle>
              <Documents />
            </>
          }
        />
        <Route index element={<Navigate to={`/companies/${id}/users`} />} />
      </Route>
    </Routes>
  )
}

export default CompanyPage
