import React, {useEffect, useState} from 'react'
import {NavLink, useNavigate} from 'react-router-dom'
import AddNewCompany from './AddNewCompany'
import {useDispatch, useSelector} from 'react-redux'
import {deleteCompany, getAllCompanies} from '../../reduxDatamanage/actions/Actions'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {AppDispatch, RootState} from '../../reduxDatamanage/store'
import style from './company.module.css'
import {ToastContainer} from 'react-toastify'

const Companys = () => {
  const companies = useSelector((state: RootState) => state.companys)
  const [allCompanies, setAllCompanies] = useState([])
  const [loader, setLoader] = useState(false)
  const dispatch: AppDispatch = useDispatch()
  const token = localStorage.getItem('token') as string
  useEffect(() => {
    if (companies) {
      setAllCompanies(companies)
    }
  }, [companies, token, loader])

  useEffect(() => {
    console.log('from companies ->', token)
    setLoader(true) // Show the loader
    if (token !== '' || null) {
      // @ts-ignore
      dispatch(getAllCompanies(token)).then(() => {
        setLoader(false)
      })
    } else {
      setLoader(false)
    }
  }, [])
  const navigate = useNavigate()

  const viewCompanyDetails = (id: number) => {
    navigate(`/companies/${id}`)
  }

  const editCompany = (company: any) => {
    navigate(`/companies/${company.id}/edit`)
  }
  const intl = useIntl()

  console.log('COMPANIES', allCompanies)

  const deleteData = (id: any) => {
    if (window.confirm('Are you sure, you want delete user')) {
      dispatch(deleteCompany(id, token))
    }
  }

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PageTitle breadcrumbs={[]}>Companies</PageTitle>
      {loader ? (
        <div className={style.loader}></div>
      ) : (
        <div className='card'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Companies List</span>
            </h3>
            <AddNewCompany />
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-150px'>Company Name</th>
                    <th className='min-w-140px'>Company code</th>
                    <th className='min-w-120px'>Users</th>
                    <th className='min-w-100px text-center'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {allCompanies?.map((company: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <p className='fs-5 fw-bold text-gray-700'>{company.name}</p>
                          </div>
                        </td>
                        <td>
                          <p className='text-dark fw-bold text-gray-700 text-hover-primary d-block fs-6'>
                            {company.company_code}
                          </p>
                        </td>
                        <td className='text-end'>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                              <p className='text-muted me-2 fs-7 fw-semibold'>{company.users}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex justify-content-center flex-shrink-0'>
                            <button
                              className='btn btn-icon btn-bg-secondary btn-active-color-primary btn-sm me-5  p-3'
                              style={{width: 'auto'}}
                              onClick={() => viewCompanyDetails(company.id)}
                            >
                              <i className='bi bi-eye-fill fs-2'></i>
                            </button>

                            <button
                              className='btn btn-icon btn-bg-secondary btn-active-color-primary btn-sm me-5  p-3'
                              style={{width: 'auto'}}
                              onClick={() => editCompany(company)}
                            >
                              <i className='bi bi-pencil-square fs-2'></i>
                            </button>
                            <button
                              className='btn btn-icon btn-bg-secondary btn-active-color-danger btn-sm me-5  p-3'
                              style={{width: 'auto'}}
                              onClick={() => deleteData(company.id)}
                            >
                              <i className='bi bi-trash3-fill fs-2'></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Companys
