import axios from 'axios'
import {
  ADD_DOCUMENT,
  ADD_NEW_COMPANY,
  ADD_NEW_USER,
  EDIT_COMPANY,
  GET_COMPANYS,
  GET_DOCUMENTS,
  GET_USERS,
  VERIFY_ERROR,
  VIEW_COMPANY,
  USERS_VIEW_DOCS,
  EDIT_USER,
} from '../variable/variable'
import {
  ADD_COMPANY,
  ADD_DOCUMENT_COMPANY,
  ADD_USER,
  DELETE_COMPANY,
  DELETE_COMPANY_USER,
  DELETE_DOCUMENT,
  DOCS_VIEW_USERS,
  GET_ALL_COMPANIES,
  GET_ALL_DOCUMENTS,
  GET_ALL_USERS,
  UPDATE_USER,
} from '../../modules/auth/ConstentVariable'
import {toast} from 'react-toastify'

// const token = localStorage.getItem('token') as string

export const getAllCompanies = (token: string) => {
  console.log('TOKEN', token)
  // @ts-ignore
  return async (dispatch) => {
    await axios
      .get(GET_ALL_COMPANIES, {headers: {'auth-token': token}})
      .then((res) => {
        dispatch({type: GET_COMPANYS, payload: res.data.data})
      })
      .catch((err) => {
        console.log('error', err)
      })
  }
}

export const getAllUsers = (id: number, token: string) => {
  console.log('TOKEN', token)

  // @ts-ignore
  return (dispatch) => {
    axios
      .get(`${GET_ALL_USERS}/${id}`, {headers: {'auth-token': token}})
      .then((res) => {
        dispatch({type: GET_USERS, payload: res.data.data})
      })
      .catch((err) => {
        console.log('error', err)
      })
  }
}

export const getAllDocuments = (id: number, token: string) => {
  // @ts-ignore
  return (dispatch) => {
    axios
      .get(`${GET_ALL_DOCUMENTS}/${id}`, {headers: {'auth-token': token}})
      .then((res) => {
        dispatch({type: GET_DOCUMENTS, payload: res.data.data})
      })
      .catch((err) => {
        console.log('error', err)
      })
  }
}

export const addNewCompany = (company: any, token: string) => {
  // @ts-ignore
  return (dispatch) => {
    axios
      .post(ADD_COMPANY, company, {headers: {'auth-token': token}})
      .then((res) => {
        dispatch({type: ADD_NEW_COMPANY, payload: res.data.data})
      })
      .catch((err) => {
        console.log('error', err)
      })
  }
}

export const addNewDocument = (formData: any, id: any, token: string) => {
  // @ts-ignore
  return async (dispatch) => {
    await axios
      .post(ADD_DOCUMENT_COMPANY, formData, {
        headers: {'auth-token': token},
      })
      .then((res) => {
        // dispatch({type: ADD_DOCUMENT, payload: res.data.data})
        dispatch(getAllDocuments(id, token))
      })

      .catch((err) => {
        dispatch({type: VERIFY_ERROR, payload: err.response.data.message})
      })
  }
}

export const editCompanyInfo = (id: any, token: string) => {
  // @ts-ignore
  return async (dispatch) => {
    console.log('id', id)
    // @ts-ignore
    await axios
      .get(`${ADD_COMPANY}/${id}`, {
        headers: {'auth-token': token},
      })
      .then((res) => {
        dispatch({type: EDIT_COMPANY, payload: res.data.data})
      })
      .catch((err) => {
        console.log('error', err)
      })
  }
}

export const viewCompanyInfo = (id: any, token: string) => {
  // @ts-ignore
  return async (dispatch) => {
    console.log('id', id)
    // @ts-ignore
    await axios
      .get(`${ADD_COMPANY}/${id}`, {
        headers: {'auth-token': token},
      })
      .then((res) => {
        dispatch({type: VIEW_COMPANY, payload: res.data.data})
      })
      .catch((err) => {
        console.log('error', err)
      })
  }
}

export const updateCompanyInfo = (id: any, companyData: any, token: string) => {
  // @ts-ignore
  return async (dispatch) => {
    console.log('id', id)
    // @ts-ignore
    await axios
      .patch(`${ADD_COMPANY}/${id}`, companyData, {
        headers: {'auth-token': token},
      })
      .then((res) => {
        dispatch({type: EDIT_COMPANY, payload: res.data.data})
      })
      .catch((err) => {
        console.log('error', err)
      })
  }
}

export const deleteUserInfo = (id: any, userid: any, token: string) => {
  // @ts-ignore
  return async (dispatch) => {
    console.log('id', id)
    const data = {
      user_id: userid,
    }
    // console.log(data, token)
    await axios
      .delete(`${DELETE_COMPANY_USER}/${id}`, {
        data,
        headers: {'auth-token': token},
      })
      .then((res) => {
        // @ts-ignore
        dispatch(getAllUsers(id, token))
        toast.success('User is successfully deleted')
      })
      .catch((err) => {
        dispatch({type: VERIFY_ERROR, payload: err.response.data.message})
        toast.error(err.response.data.message)
      })
  }
}

export const deleteDocument = (id: any, doc_id: any, token: string) => {
  // @ts-ignore
  return async (dispatch) => {
    console.log('id', id)
    await axios
      .delete(`${DELETE_DOCUMENT}/${doc_id}`, {
        headers: {'auth-token': token},
      })
      .then((res) => {
        // @ts-ignore
        dispatch(getAllDocuments(id, token))
        toast.success('Document is successfully deleted')
      })
      .catch((err) => {
        console.log('Error deleting document', err)
        dispatch({type: VERIFY_ERROR, payload: err.response.data.message})
        toast.error(err.response.data.message)
      })
  }
}

export const usersViewDocuments = (id: any, token: string) => {
  console.log('ID =>', id)
  // @ts-ignore

  return async (dispatch) => {
    await axios
      .get(`${DOCS_VIEW_USERS}/${id}`, {headers: {'auth-token': token}})
      .then((res) => {
        dispatch({type: USERS_VIEW_DOCS, payload: res.data.data})
        // console.log('ACTIONS DATA =>', res.data.data)
      })
      .catch((err) => {
        console.log('Error =>', err)
      })
  }
}
export const deleteCompany = (id: number, token: string) => {
  // @ts-ignore
  return async (dispatch) => {
    console.log('id', id)
    await axios
      .delete(`${DELETE_COMPANY}/${id}`, {
        headers: {'auth-token': token},
      })
      .then((res) => {
        // @ts-ignore
        dispatch(getAllCompanies(token))
        toast.success('Company is successfully deleted')
      })
      .catch((err) => {
        console.log('Error deleting company', err)
        dispatch({type: VERIFY_ERROR, payload: err.response.data.message})
        toast.error(err.response.data.message)
      })
  }
}
// export const editUserDetails = (id: any) => {
//   console.log('USER ID', id)

//   // @ts-ignore
//   return async (dispatch) => {
//     console.log('id', id)
//     // @ts-ignore
//     await axios
//       .post(`${UPDATE_USER}/${id}`, {
//         headers: {'auth-token': token},
//       })
//       .then((res) => {
//         console.log('ACTION', res)

//         dispatch({type: EDIT_USER, payload: res.data.data})
//       })
//       .catch((err) => {
//         console.log('error', err)
//       })
//   }
// }
