export const LOGIN = `${process.env.REACT_APP_EVEN_API}api/admin/login`
export const GET_ALL_COMPANIES = `${process.env.REACT_APP_EVEN_API}api/admin/companies`
export const GET_ALL_USERS = `${process.env.REACT_APP_EVEN_API}api/admin/users`
export const ADD_COMPANY = `${process.env.REACT_APP_EVEN_API}company`
export const GET_ALL_DOCUMENTS = `${process.env.REACT_APP_EVEN_API}api/admin/documents`
export const ADD_USER_COMAPNY = `${process.env.REACT_APP_EVEN_API}company-users/add-company-code`
export const ADD_USER = `${process.env.REACT_APP_EVEN_API}user`
export const ADD_DOCUMENT_COMPANY = `${process.env.REACT_APP_EVEN_API}documents/upload`
export const DELETE_COMPANY_USER = `${process.env.REACT_APP_EVEN_API}api/admin/company_user`
export const DOCS_VIEW_USERS = `${process.env.REACT_APP_EVEN_API}api/admin/document_view_wise_users`
export const DELETE_DOCUMENT = `${process.env.REACT_APP_EVEN_API}api/admin/document`
export const DELETE_COMPANY = `${process.env.REACT_APP_EVEN_API}api/admin/company`
export const UPDATE_USER = `${process.env.REACT_APP_EVEN_API}api/admin/update/user`
export const BASE_URL = process.env.REACT_APP_EVEN_API
