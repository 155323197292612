/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import axios from 'axios'
import {ToastContainer, toast} from 'react-toastify'
import {LOGIN} from '../ConstentVariable'

export function Login() {
  const [admin, setAdmin] = useState({email: '', password: ''})
  const [loading, setLoading] = useState(false)
  // const {saveAuth, setCurrentUser} = useAuth()
  const navigate = useNavigate()

  const validationSchema = Yup.object({
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().min(8).max(15).required('Password is required'),
  })

  const formik = useFormik({
    initialValues: admin,
    validationSchema: validationSchema,
    onSubmit: async () => {
      const {email, password} = formik.values

      try {
        setLoading(true)
        const response = await axios.post(LOGIN, {email: email, password: password})
        localStorage.setItem('token', response.data.data.JWToken)
        navigate('/companies')
      } catch (error) {
        toast.error('Email or Password is Wrong...')
        console.log('error', error)
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
          {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
        </div>
        {/* <div className='separator separator-content my-14'>
          <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
        </div>
        <div className='mb-10 bg-light-info p-8 rounded'></div> */}
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            className='form-control bg-transparent'
            placeholder='Email'
            type='email'
            name='email'
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='text-danger'>* {formik.errors.email}</div>
          )}
        </div>
        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          <input
            className='form-control bg-transparent'
            type='password'
            name='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autoComplete='off'
          />
          {formik.touched.password && formik.errors.password && (
            <div className='text-danger'>* {formik.errors.password}</div>
          )}
        </div>
        {/* <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />
        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
      </div> */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={loading}
          >
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            ) : (
              <span className='indicator-label'>Continue</span>
            )}
          </button>
        </div>
        {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
      </form>
    </>
  )
}
