/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      {/* <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      /> */}
      <SidebarMenuItem
        to='/companies'
        icon='abstract-26'
        // title={intl.formatMessage({id: 'MENU.COMPANYS'})}
        title='Companies'
        fontIcon='bi bi-buildings'
      />
    </>
  )
}

export {SidebarMenuMain}
