import React, {useEffect, useState} from 'react'
import style from './documents.module.css'
import AddDocuments from './AddDocuments'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {deleteDocument, getAllDocuments} from '../../../reduxDatamanage/actions/Actions'
import axios from 'axios'
import {AppDispatch, RootState} from '../../../reduxDatamanage/store'
import {ToastContainer, toast} from 'react-toastify'
import {BASE_URL, LOGIN} from '../../../modules/auth/ConstentVariable'
import Users from './Users'
const Documents = () => {
  // @ts-ignore
  const documents = useSelector((state) => state.documents)
  const dispatch: AppDispatch = useDispatch()
  const {id} = useParams()
  const setUrl: string = documents?.url
  const [loader, setLoader] = useState(true)
  const [allDocuments, setAllDocuments] = useState<any[]>([])
  const token = localStorage.getItem('token') as string
  const error = useSelector((state: RootState) => state.error)
  const [isError, setIsError] = useState()

  useEffect(() => {
    if (error) {
      setIsError(error)
    }
  }, [error])

  useEffect(() => {
    if (token !== '' || null) {
      // @ts-ignore
      dispatch(getAllDocuments(id, token))

      setLoader(false)
    } else {
      setLoader(false)
    }
  }, [])

  useEffect(() => {
    if (documents) {
      setAllDocuments(documents)
    }
  }, [documents])

  console.log('DOCUMENTS  =====>', allDocuments)

  const downloadFile = (url: string) => {
    console.log('url ->', url)
    window.open(`${BASE_URL}${url}`)
  }

  const openUrl = (url: string) => {
    const newurl = url.replace('/', '')
    window.open(`${process.env.REACT_APP_EVEN_API}${newurl}`)
  }

  const formatDate = (dateString: any) => {
    const today = new Date(dateString)
    const yyyy = today.getFullYear()
    let mm: any = today.getMonth() + 1
    let dd: any = today.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm

    const formattedToday = dd + '-' + mm + '-' + yyyy

    return formattedToday
  }

  const deleteData = async (DocId: any) => {
    // try {
    if (window.confirm('Are you sure you want to delete this document?')) {
      // Assuming dispatch is an async function, await the result
      dispatch(deleteDocument(id, DocId, token))
      console.log('Result', isError)

      //     if (isError) {
      //       toast.error(isError)
      //     } else {
      //       toast.success('Document is successfully deleted')
      //     }
      //   }
      // } catch (error) {
      //   toast.error('An error occurred while deleting the document.')
    }
  }

  return (
    <>
      <div className='card'>
        {loader ? (
          <div className={style.loader}></div>
        ) : (
          <>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Documents List</span>
              </h3>
              <AddDocuments />
            </div>
            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-150px'>Documents</th>
                      <th className='min-w-140px'>Version</th>
                      <th className='min-w-140px'>Uploaded On </th>
                      <th className='min-w-140px'>View By Users</th>
                      <th className='min-w-140px'>Total Views</th>
                      <th className='min-w-100px text-center'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allDocuments?.map((docs: any) => {
                      return (
                        <tr key={docs.id}>
                          <td>
                            <div className='d-flex align-items-center'>
                              <p className='text-gray-700 fs-7 fw-semibold'>{docs.zip_path}</p>
                            </div>
                          </td>
                          <td>
                            <p className='text-gray-700 fw-bold text-hover-primary d-block fs-6'>
                              {docs.version}
                            </p>
                          </td>
                          <td>
                            <p className='text-gray-700 fw-bold'>{formatDate(docs.updatedAt)}</p>
                          </td>
                          <td>
                            <p className='text-gray-600 fw-bolder fs-5'>
                              {docs.document_view_by_user ? docs.document_view_by_user : 0}
                            </p>
                          </td>
                          <td>
                            <p className='text-gray-600 fw-bolder fs-5'>{docs.total_views}</p>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <Users id={docs.id} />
                              <button
                                className='btn btn-icon btn-bg-secondary text-gray-700 btn-active-color-primary btn-sm me-5  p-3 fw-bold  p-4'
                                style={{width: 'auto'}}
                                // onClick={() =>
                                //   window.open(`${process.env.REACT_APP_EVEN_API}${docs.url}`, '_blank')
                                // }
                                onClick={() => openUrl(docs.url)}
                              >
                                URL
                              </button>
                              <button
                                className='btn btn-icon btn-bg-secondary btn-active-color-primary btn-sm me-5  p-3'
                                style={{width: 'auto'}}
                                onClick={() => downloadFile(docs.zip_path)}
                              >
                                {' '}
                                <i className='bi bi-download fs-2'></i>
                              </button>
                              <button
                                className='btn btn-icon btn-bg-secondary btn-active-color-danger btn-sm me-5  p-3'
                                style={{width: 'auto'}}
                                onClick={() => deleteData(docs.id)}
                              >
                                <i className='bi bi-trash3-fill fs-2'></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Documents
