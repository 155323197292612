import React, {useEffect, useState} from 'react'
import {NavLink, useLocation, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Dropdown1} from '../../../../_metronic/partials'
import {useDispatch, useSelector} from 'react-redux'
import {
  getAllCompanies,
  getAllDocuments,
  viewCompanyInfo,
} from '../../../reduxDatamanage/actions/Actions'
import {ToastContainer} from 'react-toastify'
import {AppDispatch, RootState} from '../../../reduxDatamanage/store'
import {number} from 'yup'
import style from './companyinfo.module.css'

const CompanyInfo = () => {
  const [details, setDetails] = useState<any>()
  const {id} = useParams()
  const location = useLocation()
  const company = useSelector((state: RootState) => state.company)
  // const loader = useSelector((state: RootState) => state.loader)
  const [loader, setLoader] = useState(false)

  const dispatch: AppDispatch = useDispatch()
  console.log('company data..', details)
  const token = localStorage.getItem('token') as string

  // console.log('loder is ', loader)

  useEffect(() => {
    setLoader(true)
    if (token !== '' || null) {
      // @ts-ignore
      dispatch(getAllDocuments(id, token))?.then(() => {
        setLoader(false)
      })
    } else {
      setLoader(false)
    }
  }, [id])

  useEffect(() => {
    setLoader(true)
    if (id) {
      // @ts-ignore
      dispatch(viewCompanyInfo(id, token))?.then(() => {
        setLoader(false)
      })
    } else {
      setLoader(false)
    }
  }, [id])
  useEffect(() => {
    if (company) {
      // @ts-ignore
      setDetails(company)
    }
  }, [company])

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {loader ? (
        <div className={style.loader}></div>
      ) : (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3 g-5'>
              <div className='me-7 mb-4 mb-sm-30px'>
                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative mt-5'>
                  {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metronic' /> */}
                  <i
                    className='bi bi-buildings'
                    style={{
                      backgroundImage: 'linear-gradient(120deg, #c7c3b3, #b19494)',
                      color: '#382929',
                      padding: '15px',
                      fontSize: '100px',
                      borderRadius: '1rem',
                    }}
                  ></i>
                  <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                </div>
              </div>

              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <div>
                        <div className='d-flex fs-4 fw-bold'>
                          <p
                            className='text-primary'
                            style={{width: '150px', marginBottom: '-4px'}}
                          >
                            {' '}
                            Company Name:
                          </p>
                          <span className=''>{details?.name}</span>
                        </div>
                        <div className='d-flex fs-4 fw-bold'>
                          <p
                            className='text-primary'
                            style={{width: '150px', marginBottom: '-4px'}}
                          >
                            {' '}
                            Company Code:
                          </p>
                          <span className=''>{details?.company_code}</span>
                        </div>
                        <div className='d-flex fs-4 fw-bold'>
                          <p className='text-primary' style={{width: '150px'}}>
                            Total Users:
                          </p>
                          <span className=''>{details?.users}</span>
                        </div>
                      </div>
                    </div>

                    {/* <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                    Developer
                  </a>
                </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex overflow-auto h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item'>
                  <NavLink
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === `companies/${id}/users` && 'active')
                    }
                    to={`/companies/${id}/users`}
                  >
                    Users
                  </NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === `companies/${id}/document` && 'active')
                    }
                    to={`/companies/${id}/document`}
                  >
                    Documents
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CompanyInfo
