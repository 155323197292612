import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch, RootState} from '../../../reduxDatamanage/store'
import {usersViewDocuments} from '../../../reduxDatamanage/actions/Actions'
import {KTIcon} from '../../../../_metronic/helpers'
import {Button, Modal} from 'react-bootstrap'
import style from './documents.module.css'

const Users = ({id}: any) => {
  const [show, setShow] = useState(false)
  const docsId = parseInt(id)

  const usersViewDocs = useSelector((state: RootState) => state.usersViewDocs)
  const dispatch: AppDispatch = useDispatch()
  const [viewDocs, setViewDocs] = useState([])
  const [loader, setLoader] = useState(false)
  const token = localStorage.getItem('token') as string

  useEffect(() => {
    if (usersViewDocs) {
      setViewDocs(usersViewDocs)
    }
    setLoader(false)
  }, [usersViewDocs])
  const showModalDetails = () => {
    setLoader(true)
    setShow(true)
    if (token !== '' || null) {
      dispatch(usersViewDocuments(docsId, token))
    } else {
      setLoader(false)
    }
  }

  console.log('USERS_DOCS =>', viewDocs)

  return (
    <>
      <div
        className='d-flex align-items-center justify-content-center btn btn-icon btn-bg-secondary btn-active-color-primary btn-sm me-5 fw-bold  p-4'
        onClick={showModalDetails}
      >
        <KTIcon iconName='profile-user' className='fs-1' />
      </div>
      <Modal show={show} className='modal-md'>
        <Modal.Header>
          <Modal.Title>User Wise Views</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loader ? (
            <div className={style.loader}></div>
          ) : viewDocs.length <= 0 ? (
            <p className='text-gray-800 fw-semibold fs-4 text-secondary'>
              This document has not been viewed by any users.
            </p>
          ) : (
            <table className='table table-striped table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Name</th>
                  <th className='min-w-140px'>Total Views</th>
                </tr>
              </thead>
              <tbody>
                {viewDocs?.map((user: any) => {
                  return (
                    <tr>
                      <td>
                        <span className='text-muted fw-semibold text-gray-700 text-hover-primary d-block fs-7'>
                          {user.name}
                        </span>
                      </td>
                      <td className='text-end'>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <span className='text-gray-700 me-2 fs-7 fw-bold'>{user.count}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}
          {/* --------------------------- */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' size='sm' className='me-3' onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Users
