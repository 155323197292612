/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import Companys from '../pages/companys/Companys'
import Protected from './Protected'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const currentUser = localStorage.getItem('token')
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='auth/*' element={<AuthPage />} />
          <Route element={<Protected />}>
            <Route path='/*' element={<PrivateRoutes />} />
            {/* <Route path='/' element={<Navigate to='dashboard' />} /> */}
            <Route path='/' element={<Navigate to='companies' />} />
            <Route path='/' element={<Navigate to='companies/:id' />} />
            <Route path='/' element={<Navigate to='companies/:id/edit' />} />
            <Route path='/' element={<Navigate to='companies/:id/users' />} />
            {/* <Route path='/' element={<Navigate to='companies/:id/users/varify' />} /> */}
            {/* <Route path='/' element={<Navigate to='companies/:id/users/adduser' />} /> */}
            <Route path='/' element={<Navigate to='companies/:id/documents' />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
