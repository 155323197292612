import React, {useState} from 'react'
import {UserType} from '../../TypeInterface'
import {useDispatch} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {AppDispatch} from '../../../reduxDatamanage/store'
import {useFormik} from 'formik'
import axios from 'axios'
import {getAllUsers} from '../../../reduxDatamanage/actions/Actions'
import {ToastContainer, toast} from 'react-toastify'
import * as Yup from 'yup'
import {ADD_USER} from '../../../modules/auth/ConstentVariable'
import {Button, Modal} from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const NewUser = ({newShow, setNewShow, mobile}: any) => {
  const [show, setShow] = useState(false)
  const [newUser, setNewUser] = useState<UserType>({
    name: '',
    mobile: '',
  })
  console.log('NEW MOBILE', mobile)

  const {id} = useParams()
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate()
  const token = localStorage.getItem('token') as string

  const validationSchema = Yup.object({
    name: Yup.string().min(3).max(25).required(),
    // mobile: Yup.number().required(),
  })

  const formik = useFormik({
    initialValues: newUser,
    validationSchema,
    onSubmit: () => {
      let {name} = formik.values
      const companyId = Number(id)
      mobile = `${mobile}`
      axios
        .post(
          `${ADD_USER}/${id}`,
          {name, mobile},
          {
            headers: {'auth-token': token},
          }
        )
        .then((res) => {
          dispatch(getAllUsers(companyId, token))
          //   navigate(`/companies/${id}`)
          setNewShow(false)
          toast.success('User added successfuly')
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            const errorMessage = err.response.data.message
            toast.error(errorMessage)
          } else {
            toast.error('An error occurred. Please try again later.')
          }
        })
    },
  })

  return (
    <>
      {/* <div
        className='d-flex align-items-center ms-5 btn btn-primary'
        onClick={handleAddNewUserPopup}
      >
        <i className='bi bi-person-plus-fill fs-2 text-light'></i>
      </div> */}
      <Modal show={newShow} className={`modal-md `}>
        <Modal.Header>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <label className='form-label'>User Name</label>
            <div className='mb-3 row'>
              <div className='col-8 size-sm'>
                <input
                  type='text'
                  name='name'
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className='form-control col-12'
                  placeholder='Enter user name'
                />
              </div>
              {formik.touched.name && formik.errors.name && (
                <div className='text-danger'>* {formik.errors.name}</div>
              )}
            </div>
            {/* <div className='mb-3'>
              <div>
                <label className='form-label'>User Mobile Number</label>

                <div className='marginBottom'>
                  <PhoneInput
                    country={'canada'}
                    value={formik.values.mobile}
                    onChange={(mobile) => formik.setFieldValue('mobile', mobile)} // Set the mobile value
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.mobile && formik.errors.mobile && (
                  <div className='text-danger'>* {formik.errors.mobile}</div>
                )}
              </div>
            </div> */}
            <Modal.Footer>
              <div className='mt-5 d-flex justify-content-end'>
                <Button
                  variant='secondary'
                  size='sm'
                  className='me-3'
                  onClick={() => setNewShow(false)}
                >
                  Close
                </Button>
                <Button type='submit' variant='primary' size='sm'>
                  Add
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default NewUser
